import React, { useState, useEffect } from "react";
import InnerWrapper from "../../components/InnerWrapper";
import moment from "moment";
import { useDispatch } from "react-redux";
import Modal from "./Modal";

import {
  Map,
  MapContent,
  MapContentWrapper,
  MapContainer,
} from "../../components/InnerLanding";

import axios from "../../axios";
import { MentorContainer } from "../../components/Mentor";
import { NavLink } from "react-router-dom";

const MentorTeams = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [teams, setTeams] = useState([]);
  const [me, setMe] = useState({});

  const openMentorTeamModal = (team) => {
    setSelectedTeam(team);
    setIsModalOpen(true);
  };

  const closeMentorTeamModal = () => {
    setIsModalOpen(false);
    setSelectedTeam(null);
  };



  const getUser = async () => {
    try {
      setLoading(true);
      dispatch({ type: "SET_LOADING", payload: true });

      const userResponse = await axios.get("/me");
      const user = userResponse.data.data;
      setMe(user);
      dispatch({ type: "SET_USER", payload: user });
    } catch (error) {
      console.error("Error fetching user:", error);
    } finally {
      setLoading(false);
    }
  };


  const getUSerTeams = async () => {
    try {
      setLoading(true);
      dispatch({ type: "SET_LOADING", payload: true });

      const UserTeamResponse = await axios.get("/teams?clients=true&page=1&per=1000");
      const UserTeams = UserTeamResponse.data.data.items;
      setTeams(UserTeams);
      dispatch({ type: "SET_USER_TEAMS", payload: UserTeams });
    } catch (error) {
      console.error("Error fetching user teams:", error)
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getUser();
    getUSerTeams();
  }, []);

  const userId = me.id;
  const allPairs = [];

  if (teams.length === 0) {
    <InnerWrapper>
    
    </InnerWrapper>
  } else {
    teams.forEach(team => {
      if (team.pairs?.length > 0) {
        const teamLeadMatch = team.team_lead?.id === userId;
        const processManagerMatch = team.process_manager?.id === userId;
        const projectManagersMatch = team.project_managers?.some(pm => pm.id === userId);
        const mentorsMatch = team.mentors?.some(mentor => mentor.id === userId);
        const clientsMatch = team.pairs.some(pair => pair.clients.some(client => client.id === userId));

        if (teamLeadMatch || processManagerMatch || projectManagersMatch || mentorsMatch || clientsMatch) {
          const newPairs = team.pairs
            .map(pair => {
              // Kullanıcının duty'sini belirle
              let userDuty = '';

              if (teamLeadMatch) {
                userDuty = 'Takım lideri';
              } else if (processManagerMatch) {
                userDuty = 'Takım süreç yöneticisi';
              } else if (projectManagersMatch) {
                userDuty = 'Takım stajyeri';
              } else if (mentorsMatch) {
                userDuty = 'Takım rehberi';
              } else if (clientsMatch && pair.clients.some(client => client.id === userId)) {
                userDuty = 'Eşleşme danışanı';
              }

              return {
                ...pair,
                userID: userId,
                teamId: team.id,
                teamName: team.name,
                teamLead: team.team_lead,
                processManager: team.process_manager,
                projectManagers: team.project_managers,
                mentors: team.mentors,
                userDuty,
                categories: team.categories
              };
            });

          newPairs.forEach(pair => {
            if (!allPairs.some(existingPair => existingPair.teamId === team.id && existingPair.id === pair.id)) {
              allPairs.push(pair);
            }
          });
        }
      }
    });
  }

  return (

    <InnerWrapper>
      <MapContainer>
        <MapContent>
          <MapContentWrapper>
            <div>Kaviyer'e HOŞGELDİNİZ!</div>
          </MapContentWrapper>
        </MapContent>
        <Map></Map>
      </MapContainer>
      <MentorContainer>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                <div className="row" style={{ display: "flex", flexDirection: "row" }} >
                  <div style={{ marginBottom: "0", width: "66%", textAlign: "start" }}>
                    <h2> Rehberlik Programları</h2>
                  </div>
                  <div style={{ marginBottom: "0", width: "33%", textAlign: "end" }}>
                    <strong>Toplam : {allPairs.length}</strong>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Rehber Takımı</th>
                        <th>Danışan(lar)</th>
                        <th>Başlangıç tarihi</th>
                        <th>Bitiş tarihi</th>
                        <th>Rehberlik Programı</th>
                        <th>Eşleşmedeki Görevi</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allPairs.map(pair => (
                        <tr key={pair.id}>
                          <td onClick={() => openMentorTeamModal(pair)} style={{ cursor: 'pointer', color: 'blue' }}>
                            {pair.teamName}
                          </td>
                          <td>
                            {pair.clients.map(client => (
                              <div key={client.id}>
                                {client.name} {client.lastname}
                              </div>
                            ))}
                          </td>
                          <td>{moment(pair.start_at).format('DD MM YYYY')}</td>
                          <td>{moment(pair.end_at).format('DD MM YYYY')}</td>
                          <td>
                            {pair.mentoring_programs.map(program => (
                              <div key={program.id}>
                                <NavLink to={`/app/program/${program.id}`} style={{ cursor: 'pointer', color: "blue" }}>
                                  {program.title}
                                </NavLink>
                              </div>
                            ))}
                          </td>
                          <td>{pair.userDuty}</td>
                        </tr>
                      ))}
                    </tbody>

                  </table>
                  <Modal isOpen={isModalOpen} onClose={closeMentorTeamModal}>
                    {selectedTeam && (
                      <div className="modal-body">
                        <dl className="row" style={{ display: "flex", flexDirection: "row" }}>
                          <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right" style={{ width: "200px" }}>Takım Adı:</dt>
                          <dd className="col-12 col-sm-9 col-md-10">{selectedTeam.teamName}</dd>
                        </dl>
                        <hr />
                        <dl className="row" style={{ display: "flex", flexDirection: "row" }}>
                          <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right" style={{ width: "200px" }}>Takım Lideri:</dt>
                          <dd className="col-12 col-sm-9 col-md-10">
                            {selectedTeam.teamLead ? (
                              <>
                                {" "}
                                {selectedTeam.teamLead?.name} {selectedTeam.teamLead?.lastname} (
                                {selectedTeam.teamLead?.email})
                              </>
                            ) : (
                              <span className="text-muted">-</span>
                            )}
                          </dd>
                        </dl>
                        <hr />
                        <dl className="row" style={{ display: "flex", flexDirection: "row" }}>
                          <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right" style={{ width: "200px" }}>Takım Stajyerleri:</dt>
                          <dd className="col-12 col-sm-9 col-md-10">
                            {selectedTeam.projectManagers?.map((d) => (
                              <li key={d.id} style={{ listStyleType: "none" }}>
                                {d.name} {d.lastname} ({d.email})
                              </li>
                            ))}
                          </dd>
                        </dl>
                        <hr />
                        <dl className="row" style={{ display: "flex", flexDirection: "row" }}>
                          <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right" style={{ width: "200px" }}>Süreç Yöneticisi:</dt>
                          <dd className="col-12 col-sm-9 col-md-10">
                            {selectedTeam.processManager ? (
                              <>
                                {selectedTeam.processManager?.name} {selectedTeam.processManager?.lastname} (
                                {selectedTeam.processManager?.email})
                              </>
                            ) : (
                              <span className="text-muted">-</span>
                            )}
                          </dd>
                        </dl>
                        <hr />
                        <dl className="row" style={{ display: "flex", flexDirection: "row" }}>
                          <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right" style={{ width: "200px" }}>Takım Rehberleri:</dt>
                          <dd className="col-12 col-sm-9 col-md-10">
                            {selectedTeam.mentors?.map((d) => (
                              <li key={d.id} style={{ listStyleType: "none" }}>
                                {d.name} {d.lastname} ({d.email})
                                <ul>
                                  {d.categories?.map((category) => (
                                    <li key={category.id}>{category.title}</li>
                                  ))}
                                </ul>
                              </li>
                            ))}
                          </dd>
                        </dl>
                        <hr />
                        <dl className="row" style={{ display: "flex", flexDirection: "row" }}>
                          <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right" style={{ width: "200px" }}>Uzmanlık:</dt>
                          <dd className="col-12 col-sm-9 col-md-10">
                            {selectedTeam && selectedTeam.categories && selectedTeam.categories.length > 0 ? (
                              selectedTeam.categories.map((d) => (
                                <li key={d.id} style={{ listStyleType: "none" }}>{d.title}</li>
                              ))
                            ) : (
                              <span className="text-muted">-</span>
                            )}
                          </dd>
                        </dl>

                      </div>
                    )}
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>

      </MentorContainer>
    </InnerWrapper>
  );
};

export default MentorTeams;
