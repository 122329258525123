import React, { useEffect, useRef } from "react";
import InnerWrapper from "../../components/InnerWrapper";
import { useDispatch, useSelector } from "react-redux";
import { Spin, Row, Col, Input, Select, Space } from 'antd';
import axios from "../../axios"
import { Map, MapContent, MapContentWrapper, MapContainer } from "../../components/InnerLanding"
import Mentor, { MentorContainer, MentorSearchOuter, MentorSearch, MensorSearchCol } from "../../components/Mentor"
import { NoData } from "../../components/NoData"
const settings = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 8,
    xl: 6
}
const { Option } = Select;
const { Search } = Input;
const Main = () => {
    const search = useRef()
    const { mentors, loading, page, per, mentorCategories, selectedMentorCategories, q } = useSelector(state => state.mentors)
    const { sectors, selectedSectors } = useSelector(state => state.user)
    const dispatch = useDispatch()
    useEffect(() => {
        page === 1 && getMentors()
    }, [q, selectedMentorCategories, selectedSectors]) // eslint-disable-line react-hooks/exhaustive-deps
    const searchHandle = (payload) => {
        dispatch({ type: "SET_PAGE", payload: 1 })
        dispatch({ type: "SET_Q", payload })
    }
    const selectHandle = (payload) => {
        dispatch({ type: 'SET_SELECTED_CATEGORIES', payload })
    }
    const sectorHandle = (payload) => {
        dispatch({ type: 'SET_SELECTED_SECTORS', payload });
    }
    const getMentors = async () => {
        try {
            let _page = page
            dispatch({ type: "SET_LOADING", payload: true })
            const { data: { data: { items } } } = await axios.get("mentors", { params: { per, page, categories: selectedMentorCategories, q, sectors: selectedSectors } })
            const _mentors = items.map(item => ({ ...item, avatar: `${process.env.REACT_APP_BASE_URL}/media/picture/${item.avatar}` }))
            dispatch({ type: "SET_MENTORS", payload: _mentors }, { type: "SET_PAGE", payload: ++_page })
        } catch (error) {
            console.log(error)
        } finally {
            dispatch({ type: "SET_LOADING", payload: false })
        }
    }
    return (
        <InnerWrapper backgroundColor="#f5f5f5">
            <MapContainer>
                <MapContent>
                    <MapContentWrapper>
                        <div>Kaviyer'e HOŞGELDİNİZ!</div>
                    </MapContentWrapper>
                </MapContent>
                <Map></Map>
            </MapContainer>
            <MentorSearchOuter>
                <MentorSearch>
                    <MensorSearchCol>
                        <h1>Rehber Seç</h1>
                        <div>
                            <Search ref={search} allowClear enterButton="ARA" placeholder="Rehber Ad ya da Soyad giriniz" size="large" disabled={loading} defaultValue={q} onSearch={val => searchHandle(val)} />
                        </div>
                    </MensorSearchCol>
                    <MensorSearchCol>
                        <Space
                            direction="vertical"
                            style={{
                                width: '100%',
                                maxWidth: '100%'
                            }}>
                            <Select style={{ width: '100%' }} defaultValue={selectedSectors} size="large" placeholder='Uzmanlık Sektörü Seçin...' mode='multiple' onChange={(val) => sectorHandle(val)}>
                                {sectors.map(sec => <Option disabled={loading} key={sec.id} value={sec.id}> {sec.title} </Option>)}
                            </Select>
                        </Space>
                    </MensorSearchCol>
                    <MensorSearchCol>
                        <Space
                            direction="vertical"
                            style={{
                                width: '100%',
                                maxWidth: 500
                            }}>
                            <Select style={{ width: '100%' }} defaultValue={selectedMentorCategories} size="large" placeholder='Uzmanlık Alanı Seçin...' maxTagCount={3} mode='multiple' onChange={(val) => selectHandle(val)}>
                                {mentorCategories.map(cat => <Option disabled={loading} key={cat.id} value={cat.id}> {cat.title} </Option>)}
                            </Select>
                        </Space>
                    </MensorSearchCol>
                </MentorSearch>
            </MentorSearchOuter>
            <MentorContainer>
                <Row gutter={[24, 24]}>
                    {mentors.filter(mentor => (
                        !mentor.name.toLowerCase().includes('stajyer') &&
                        !mentor.lastname.toLowerCase().includes('stajyer') &&
                        !mentor.categories.some(category => category.title.toLowerCase().includes('stajyer'))
                    )).map(filteredMentor => (
                        <Col {...settings} key={filteredMentor.id}>
                            <Mentor {...filteredMentor} />
                        </Col>
                    ))}
                </Row>
                {loading && <Spin size="large" tip="Yükleniyor..." />}
                {!loading && mentors.length === 0 && <NoData />}
            </MentorContainer>

        </InnerWrapper>
    )
}
export default Main;