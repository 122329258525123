import React from "react";
import Login from './pages/Login'
import Register from './pages/Register'
import ForgotPassword from './pages/ForgotPassword'
import PasswordReset from './pages/PasswordReset'
import MentorRegister from './pages/MentorRegister'
import Home from './pages/Home'
import Dumb from './pages/Dumb'
import Detail from './pages/Dumb/Detail'
import Main from "./pages/Main/Index"
import Profile from './pages/Main/Profile'
import {PrivateRoute, PublicRoute} from './route/RouteManagement';
import MentorActivate from "./pages/MentorActivate";
import UserActivate from "./pages/UserActivate";
import Calendar from "./pages/Calendar.js";
import Contracts from "./pages/Contracts"
import Corporate from "./pages/Corporate"
import InnerMentorRegister from "./pages/InnerMentorRegister"
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import "./assets/styles/main.less";
import InnerUserRegister from "./pages/InnerUserRegister";
import SubuserOperation from "./pages/SubuserOperation";
import Faq from "./pages/Faq";
import Subuser from "./pages/Subuser";
import Messages from "./pages/Messages";
import Support from "./pages/Support";
import Reviews from "./pages/Main/Reviews";
import MentorTeams from "./pages/Main/MentorTeam";
import ProgramDetailsPage from "./pages/Main/ProgramsDetailPage.js";
import { Records } from "./pages/Records";

const App = () => {
  return (
    <Router>
      <Switch>
        <PublicRoute exact path={"/"} component={Home}/>
        <PublicRoute exact path={"/giris"} component={Login}/>
        <PublicRoute exact path={"/kayit"} component={Register}/>
        <Route exact path={"/kullanici-onay/:token"} component={UserActivate}/>
        <PublicRoute exact path={"/sifremi-unuttum"} component={ForgotPassword}/>
        <Route exact path={"/kurumsal/:slug"} component={Corporate}/>
        <PublicRoute exact path={"/sifremi-sifirla/:token"} component={PasswordReset}/>
        <PublicRoute exact path={"/kayit-mentor"} component={MentorRegister}/>
        <PublicRoute exact path={"/mentor-onay/:token"} component={MentorActivate}/>
        <PublicRoute exact path={"/haberler/:slug/:type"} component={Detail}/>
        <PublicRoute exact path={"/statik/:dumb"} component={Dumb}/>
        <PublicRoute exact path={"/bloglar/:slug/:type"} component={Detail}/>
        <PublicRoute exact path={"/sozlesmeler/:slug"} component={Contracts}/>
        <PublicRoute exact path={"/merak-edilenler/sss"} component={Faq}/>
        <PrivateRoute exact path={"/app/sss"} component={Faq}/>
        <PrivateRoute exact path={"/app"} component={Main}/>
        <PrivateRoute exact path={"/app/gorusme-talepleri"} component={Reviews}/>
        <PrivateRoute exact path={"/app/gorusme-kayitlarim"} component={Records}/>
        <PrivateRoute exact path={"/app/destek"} component={Support}/>
        <PrivateRoute exact path={"/app/sozlesmeler/:slug"} component={Contracts}/>
        <PrivateRoute exact path={"/app/takvim"} component={Calendar}/>
        <PrivateRoute exact path={"/app/profile"} component={Profile}/>
        <PrivateRoute exact path={"/app/mentor-kayit"} component={InnerMentorRegister}/>
        <PrivateRoute exact path={"/app/user-kayit"} component={InnerUserRegister}/>
        <PrivateRoute exact path={"/app/kullanicilar"} component= {Subuser}/>
        <PrivateRoute exact path={"/app/kullanici-islemi"} component= {SubuserOperation}/>
        <PrivateRoute exact path={"/app/mesajlarim"} component= {Messages}/>
        <PrivateRoute exact path={"/app/rehberlik-programları"} component={MentorTeams}/>
        <PrivateRoute exact path={"/app/program/:programId"} component={ProgramDetailsPage}/>
        <Route exact path={"*"}>
          <div>404</div>
        </Route>
      </Switch>
    </Router>
  );
}
export default App;
