const SET_USER_TEAMS = "SET_USER_TEAMS";
const SET_LOADING = "SET_LOADING";
const SET_PAGE = "SET_PAGE";

export const set_user_teams = (payload) => ({
    type: SET_USER_TEAMS,
    payload
})
export const set_loading = (payload) => ({
    type: SET_LOADING,
    payload
})
export const set_page = (payload) => ({
    type: SET_PAGE,
    payload
})
export const initialState = {
    UserTeams: [],
    page: 1,
    loading: false,
}
const Userteams = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_TEAMS:
            return {
                ...state,
                Userteams: action.payload,
            };
        case SET_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case SET_PAGE:
            return {
                ...state,
                page: action.payload
            }
        default:
            return state
    }
}
export default Userteams;