import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import InnerWrapper from "../../components/InnerWrapper";
import { useDispatch } from "react-redux";
import axios from "../../axios";
import {
  Map,
  MapContent,
  MapContentWrapper,
  MapContainer,
} from "../../components/InnerLanding";
import { MentorContainer } from "../../components/Mentor";
import SessionModal from "./sessionModal";
const ProgramDetailsPage = () => {
  const { programId } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSession, setSelectedSession] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [teams, setTeams] = useState([]);
  const [me, setMe] = useState([]);



  const openSessionModal = (session) => {
    setIsModalOpen(true);
    setSelectedSession(session);
  };

  const closeSessionModal = () => {
    setIsModalOpen(false);
    setSelectedSession(null);
  };
  const getUser = async () => {
    try {
      setLoading(true);
      dispatch({ type: "SET_LOADING", payload: true });

      const userResponse = await axios.get("/me");
      const user = userResponse.data.data;
      setMe(user);
      dispatch({ type: "SET_USER", payload: user });
    } catch (error) {
      console.error("Error fetching user:", error);
    } finally {
      setLoading(false);
    }
  };


  const getTeams = async () => {
    try {
      setLoading(true);
      dispatch({ type: "SET_LOADING", payload: true });

      const TeamsResponse = await axios.get("/teams?clients=true&page=1&per=1000");
      const Teams = TeamsResponse.data.data.items;
      setTeams(Teams);
      dispatch({ type: "SET_TEAMS", payload: Teams });
    } catch (error) {
      console.error("Error fetching teams:", error)
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getTeams();
    getUser();
  }, []);

  const userId = me.id;

  const allPairs = [];

  if (teams.length === 0) {
    <InnerWrapper>
      console.log("Loading...");
    </InnerWrapper>
  } else {
    teams.forEach(team => {
      if (team.pairs.length > 0) {
        const teamLeadMatch = team.team_lead?.id === userId;
        const processManagerMatch = team.process_manager?.id === userId;
        const projectManagersMatch = team.project_managers?.some(pm => pm.id === userId);
        const mentorsMatch = team.mentors?.some(mentor => mentor.id === userId);
        const clientsMatch = team.pairs.some(pair => pair.clients.some(client => client.id === userId));

        if (teamLeadMatch || processManagerMatch || projectManagersMatch || mentorsMatch || clientsMatch) {
          const newPairs = team.pairs
            .map(pair => {
              let userDuty = '';

              if (teamLeadMatch) {
                userDuty = 'Takım lideri';
              } else if (processManagerMatch) {
                userDuty = 'Takım süreç yöneticisi';
              } else if (projectManagersMatch) {
                userDuty = 'Takım stajyeri';
              } else if (mentorsMatch) {
                userDuty = 'Takım rehberi';
              } else if (clientsMatch && pair.clients.some(client => client.id === userId)) {
                userDuty = 'Eşleşme danışanı';
              }

              return {
                ...pair,
                userID: userId,
                teamId: team.id,
                teamName: team.name,
                teamLead: team.team_lead,
                processManager: team.process_manager,
                projectManagers: team.project_managers,
                mentors: team.mentors,
                userDuty,
                categories: team.categories
              };
            });

          newPairs.forEach(pair => {
            if (!allPairs.some(existingPair => existingPair.teamId === team.id && existingPair.id === pair.id)) {
              allPairs.push(pair);
            }
          });
        }
      }
    });
  }
  const programDetails = allPairs
    .flatMap(pair => pair.mentoring_programs)
    .find(program => program.id === programId);


  if (!programDetails) {
    return <InnerWrapper>
      <MapContainer>
        <MapContent>
          <MapContentWrapper>
            <div>Kaviyer'e HOŞGELDİNİZ!</div>
          </MapContentWrapper>
        </MapContent>
        <Map>

        </Map>
      </MapContainer>
    </InnerWrapper>
  }
  return (
    <InnerWrapper>
      <MapContainer>
        <MapContent>
          <MapContentWrapper>
            <div>Kaviyer'e HOŞGELDİNİZ!</div>
          </MapContentWrapper>
        </MapContent>
        <Map></Map>
      </MapContainer>
      <MentorContainer>
        <div className="program-details">
          <h1>Program Detayları</h1>
          {/* Program bilgilerini buraya yerleştirin */}
          <dl className="row" style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: "75px" }}>
            <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">Program Adı:</dt>
            <dd className="col-12 col-sm-9 col-md-10" style={{ flex: "1", wordWrap: "break-word", overflowWrap: "break-word", marginLeft: "0" }}>{programDetails.title}</dd>
          </dl>
          <hr />
          <dl className="row" style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: "60px" }}>
            <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">Oturum Konuları ve Başlıklar:</dt>
            <dd className="col-12 col-sm-9 col-md-10" style={{ flex: "1", wordWrap: "break-word", overflowWrap: "break-word", marginLeft: "0" }}>
              {programDetails.sessions.map((session, index) => (
                <div key={index}>
                  <ul>
                    <li onClick={() => openSessionModal(session)} style={{ cursor: "pointer", color: "blue" }}>{session.title}</li>
                  </ul>
                </div>
              ))}
            </dd>
          </dl>
          <SessionModal isOpen={isModalOpen} onClose={closeSessionModal}>
            {selectedSession ? (
              <div className="modal-body">
                <dl className="row" style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                  <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right" style={{ width: "200px" }}>Oturum Başlığı:</dt>
                  <dd className="col-12 col-sm-9 col-md-10" style={{ flex: "1", wordWrap: "break-word", overflowWrap: "break-word", marginLeft: "0" }}>
                    {selectedSession.title}
                  </dd>
                </dl>
                <dl className="row" style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                  <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right" style={{ width: "200px" }}>Oturum Lokasyonu:</dt>
                  <dd className="col-12 col-sm-9 col-md-10" style={{ flex: "1", wordWrap: "break-word", overflowWrap: "break-word", marginLeft: "0" }}>
                    {selectedSession.location}
                  </dd>
                </dl>
                <dl className="row" style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                  <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right" style={{ width: "200px" }}>Oturum Hedefleri:</dt>
                  <dd className="col-12 col-sm-9 col-md-10" style={{ flex: "1", wordWrap: "break-word", overflowWrap: "break-word", marginLeft: "0" }}>
                    {selectedSession.goals}
                  </dd>
                </dl>
                <dl className="row" style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                  <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right" style={{ width: "200px" }}>Oturum Hazırlıkları:</dt>
                  <dd className="col-12 col-sm-9 col-md-10" style={{ flex: "1", wordWrap: "break-word", overflowWrap: "break-word", marginLeft: "0" }}>
                    {selectedSession.preparations}
                  </dd>
                </dl>
                <dl className="row" style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                  <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right" style={{ width: "200px" }}>Oturum Gündemi:</dt>
                  <dd className="col-12 col-sm-9 col-md-10" style={{ flex: "1", wordWrap: "break-word", overflowWrap: "break-word", marginLeft: "0" }}>
                    {selectedSession.agenda}
                  </dd>
                </dl>
                <dl className="row" style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                  <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right" style={{ width: "200px" }}>Oturum Linki:</dt>
                  <dd className="col-12 col-sm-9 col-md-10" style={{ flex: "1", wordWrap: "break-word", overflowWrap: "break-word", marginLeft: "0" }}>
                    <a href={selectedSession.link}>
                      {selectedSession.link}
                    </a>
                  </dd>
                </dl>
                {(me.roles.includes("ADMIN") || me.roles.includes("MENTOR")) && (
                  <>
                    <dl className="row" style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                      <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right" style={{ width: "200px" }}>Oturum Dökümanları:</dt>
                      <dd className="col-12 col-sm-9 col-md-10" style={{ flex: "1", wordWrap: "break-word", overflowWrap: "break-word", marginLeft: "0" }}>
                        <a href={selectedSession.documents}>{selectedSession.documents}</a>
                      </dd>
                    </dl>
                    <dl className="row" style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                      <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right" style={{ width: "200px" }}>Oturum Kayıt Linki:</dt>
                      <dd className="col-12 col-sm-9 col-md-10" style={{ flex: "1", wordWrap: "break-word", overflowWrap: "break-word", marginLeft: "0" }}>
                        <a href={selectedSession.registerLink}>{selectedSession.registerLink}</a>
                      </dd>
                    </dl>
                  </>
                )}
                <dl className="row" style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                  <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right" style={{ width: "200px" }}>Oturum Değerlendirmesi:</dt>
                  <dd className="col-12 col-sm-9 col-md-10" style={{ flex: "1", wordWrap: "break-word", overflowWrap: "break-word", marginLeft: "0" }}>
                    {selectedSession.assessment}
                  </dd>
                </dl>
                <dl className="row" style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                  <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right" style={{ width: "200px" }}>Oturum Konuları:</dt>
                  <dd className="col-12 col-sm-9 col-md-10" style={{ flex: "1", wordWrap: "break-word", overflowWrap: "break-word", marginLeft: "0" }}>
                    {selectedSession.topics}
                  </dd>
                </dl>
                <dl className="row" style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                  <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right" style={{ width: "200px" }}>Oturum Kararları:</dt>
                  <dd className="col-12 col-sm-9 col-md-10" style={{ flex: "1", wordWrap: "break-word", overflowWrap: "break-word", marginLeft: "0" }}>
                    {selectedSession.decisions}
                  </dd>
                </dl>
                <dl className="row" style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                  <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right" style={{ width: "200px" }}>Oturum Tarihi:</dt>
                  <dd className="col-12 col-sm-9 col-md-10" style={{ flex: "1", wordWrap: "break-word", overflowWrap: "break-word", marginLeft: "0" }}>
                    {selectedSession.date}
                  </dd>
                </dl>
              </div>
            ) : null}
          </SessionModal>
          <hr />

        </div>
      </MentorContainer>
    </InnerWrapper>
  );
};

export default ProgramDetailsPage;
