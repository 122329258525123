import {
  MentorAvatar,
  MentorHead,
  MentorHeadContent,
  MentorFullname,
  MentorCountry,
  MentorSpacer,
  MentorModalContainer,
  MentorRate,
  MentorContent,
  MentorDescription
} from './Mentor'
import { avatarFullPath, fullname, fullLocation, businessTypes } from '../utils/helpers'
import MentorAvailability from './MentorAvailability'
import { Rate, Tag, Row, Col } from 'antd'
import { LinkedinFilled } from '@ant-design/icons'
import SubSector from './SubSector'
//import { useSelector } from 'react-redux'
const settings = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 12
}

const MentorModal = ({ mentor, show = true, isUser = false }) => {
  //const { role } = useSelector(state => state.user)
  return (
    <MentorModalContainer>
      <MentorHead>
        <MentorAvatar
          src={avatarFullPath(mentor.avatar)}
          circle
        />
        <MentorHeadContent>
          <MentorFullname> {fullname(mentor.name, mentor.lastname)} </MentorFullname>
          <MentorCountry>{fullLocation(mentor.city, mentor.district)}</MentorCountry>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Rate
              allowHalf
              disabled
              style={{ fontSize: 12 }}
              value={mentor.score}
            />{' '}
            <MentorRate>{mentor.score.toFixed(2)}</MentorRate>
          </div>
          <div>
            {' '}
            <strong>{mentor.score_count}</strong> değerlendirme{' '}
          </div>
          {isUser && (
            <div>
              {' '}
              <LinkedinFilled style={{ background: '#fff', color: '#0a66c2' }} /> {mentor.linkedin}{' '}
            </div>
          )}
        </MentorHeadContent>
      </MentorHead>
      <MentorSpacer />
      <MentorContent
        height="true"
        noMargin
      >
        <MentorFullname>Hakkında</MentorFullname>
        <MentorDescription more>{mentor.about}</MentorDescription>
      </MentorContent>
      <MentorSpacer />
      <MentorContent
        height="true"
        noMargin
      >
        <MentorFullname>Çalışma Sektörü</MentorFullname>
        <div style={{ marginTop: 10 }}>
          <SubSector
            isUser
            items={mentor.user_sectors}
          />
        </div>
      </MentorContent>
      <MentorSpacer />
      {!isUser && (
        <>
          <MentorContent
            height="true"
            noMargin
          >
            <MentorFullname>Uzmanlık Alanları</MentorFullname>
            <div style={{ marginTop: 10 }}>
              {mentor.categories.map(category => (
                <Tag
                  style={{ marginBottom: 5 }}
                  color="cyan"
                  key={category.id}
                >
                  {' '}
                  {category.title}{' '}
                </Tag>
              ))}
            </div>
          </MentorContent>
          <MentorSpacer />
        </>
      )}
      {isUser &&
        mentor.business_type !== 'company_at_idea_stage' &&
        mentor.business_type !== 'individual_application' && (
          <MentorContent
            height="true"
            noMargin
          >
            <MentorFullname>İşletme Bilgileri</MentorFullname>
            <div style={{ marginTop: 10 }}>
              <Row>
                <Col {...settings}>
                  <div>
                    {' '}
                    <strong>İşletme Türü:</strong> {businessTypes[mentor.business_type]}{' '}
                  </div>
                </Col>
                <Col {...settings}>
                  <div>
                    {' '}
                    <strong>İşletme Adı:</strong> {mentor.business_name}{' '}
                  </div>
                </Col>
                <Col {...settings}>
                  <div>
                    {' '}
                    <strong>İşletme Görevi:</strong> {mentor.business_role}{' '}
                  </div>
                </Col>
                <Col {...settings}>
                  <div>
                    {' '}
                    <strong>İşletme Kuruluş Yılı:</strong> {mentor.business_working_time}{' '}
                  </div>
                </Col>
                <Col>
                  <SubSector
                    hide
                    isUser
                    items={mentor.business_sectors}
                  />
                </Col>
              </Row>
            </div>
          </MentorContent>
        )}
      {show && mentor.roles.includes('MENTOR') && (
        <div>
          <MentorFullname>Görüşme Zamanını Seçiniz</MentorFullname>
          <MentorAvailability
            id={mentor.id}
            availibility={mentor.availability}
          ></MentorAvailability>
        </div>
      )}
    </MentorModalContainer>
  )
}

export default MentorModal
