import React from 'react';
import { Button } from 'antd';
const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <p className="modal-title">Rehber Takımı</p>
          <Button type="button" onClick={onClose} className="close-button">
            <span aria-hidden="true">&times;</span>
          </Button>
        </div>
        <div className='modal-body'>
          {children}
        </div>
        <div className='modal-footer'>
        <Button type="button" onClick={onClose} className="close-button-footer">
           close
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
