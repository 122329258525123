/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import styled from 'styled-components'
import Footer from './Footer'
import InnerHeader from './InnerHeader'
import axios from "../axios"
const Layout = styled.div`
  width:100%;
  margin:0px auto;
  padding:150px 0px 0px 0px;
  background:${props => props.backgroundColor};
  max-width:${props => props.maxWidth ? props.maxWidth : '100%'};
`
Layout.defaultProps = {
  backgroundColor: "#fff"
}
const InnerWrapper = ({ children, ...rest }) => {
  const dispatch = useDispatch()
  const { me } = useSelector(state => state.user)
  const getUser = async () => {
    try {
      const { data: { data } } = await axios.get("/me")
      const _data = {
        ...data,
        avatar: `${process.env.REACT_APP_BASE_URL}/media/picture/${data.avatar}`
      }
      dispatch({ type: "SET_USER", payload: _data })
    } catch (error) {
      console.log(error)
    }
  }

  const initialReqs = async () => {
    await getUser()
  }
  useEffect(() => {
    if (!me.hasOwnProperty("id")) {
      initialReqs()
    }
  }, [])
  return (
    <>
      <InnerHeader />
      <Layout {...rest}>
        {children}
      </Layout>
      <Footer />
    </>
  )
}
export default InnerWrapper;